







































































































































































































































.rowJsontitle {
  padding: 20px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-around;
  > div {
    display: flex;
    align-items: center;
    font-size: 18px;
    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
}
.listExam {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        display: flex;
        .number {
          padding-left: 1rem;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        .radioGroup {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-items: flex-start;
          align-content: center;
          label {
            margin: 3px 0;
            display: flex;
            justify-content: flex-start;
          }
          .el-radio__label {
            width: 100%;
            word-wrap: break-word;
            word-break: break-all;
            white-space: pre-wrap;
            line-height: 20px;
          }
        }
      }
    }
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
