.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.checkStyle {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-end;
  overflow: auto;
}
.checkStyleBox {
  margin: 0 5px;
}
